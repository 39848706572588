@import 'src/scss/variables/colors';
@import 'src/scss/variables/variables';
@import 'src/scss/variables/theme';
@import 'src/scss/variables/mixins';

.footer-container {
  background-color: $basil-50;
  color: $basil-500;
}

.footer {
  margin: 0 auto;
  max-width: $content-max-width;
  padding: 32px 16px;
  .hr {
    border: 0;
    border-top: 1px solid $basil-100;
    margin: 2rem 0 2rem 0
  }
}

.footer-main {
  gap: 32px;
  .application-logo {
    height: 47px;
    width: 178px;
    margin-bottom: 16px;
  }
}

.link-footer {
  color: $basil-300;
  font-size: 13px;
  font-weight: 400;
  line-height: 125%;
  text-align: left;

  &:hover,
  &:active,
  &:focus {
    color: $basil-300;
    text-decoration: underline;
  }

  &:visited {
    color: $basil-300;
  }
}

.link-store {
  border-radius: $default-border-radius;
  line-height: 0;
  width: 160px;
}

.text-copyright {
  color: $basil-500;
  border-top: 1px $basil-100 solid;
  font-size: 13px;
  padding: 16px;
}

.text-follow {
  font-size: 13px;
  font-weight: 400;
  line-height: 125%;
}

.container-social-icons {
  gap: 10px;
}

.social-icon-instagram {
  font-size: 18px;
  color: $basil-500;

  &:hover:before {
    content: '\e948';
  }
}

.social-icon-twitter {
  font-size: 18px;
  color: $basil-500;

  &:hover:before {
    content: '\e94b';
  }
}

.social-icon-facebook {
  font-size: 18px;
  color: $basil-500;

  &:hover:before {
    content: '\e947';
  }
}

.social-icon-tiktok {
  font-size: 18px;
  color: $basil-500;

  &:hover:before {
    content: '\e969';
  }
}

.title-group {
  font-size: 19px;
  font-weight: 500;
  line-height: 125%;
  text-transform: uppercase;
}

.group-links {
  width: 140px;
  gap: 10px;
  margin-bottom: 25px;
}

.social-links {
  gap: 10px;
  @include desktop {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media screen and (max-width: $desktop-device) {
  .footer-main {
    flex-direction: column;
    align-items: center !important;
  }

  .footer {
    .application-logo {
      align-self: center;
      margin-right: 0;
    }
  }
}

.youtube-icon {
  width: 18px;
  & path {
    stroke: white;
    fill: $basil-500 !important;
  }
}
